import React from 'react'
import Header from '../components/Header';

export default function ErrorPage() {
  return (
    <div className="container">
    <div className="col-md-5">
        <div className="form-area">
  
                <br style={{ clear: "both" }} />
                <div style={{ marginBottom: 25, textAlign: "center" }}>
                    <Header />
                </div>
                 <p> System Error Please try again and check your Story </p>
         
                <hr style={{ color: 'white' }} />
                <div align="center" style={{ border: "1px" }}>
                    Inspired to change your world</div>
 
        </div>
    </div>
</div>
  )
}
