import React from 'react'
import '../App.css'
import econetImg from '../assets/econet.png';
import ialtdImg from '../assets/ialtd.png';
export default function Header(){

   return(
     <header>
    <p><img src={econetImg} alt="Econet Wireless" /> </p>
     <p><img src={ialtdImg} alt="ialtd" /> </p>
   </header>
   )
 
}
