import React, { useState } from 'react';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../utils/axios';
import {ProgressBar } from "react-bootstrap"


export default function Form() {
    const navigate = useNavigate()
    const [file, setFile] = useState('')
    const [progress, setProgress] = useState()
    const url = "api/add-story";


    const handleSubmit = async (e) => {

        e.preventDefault();


        try {

            var cellNumber = document.getElementById("cellNumber").value;
            var fullName = document.getElementById("fullName").value;
            var story = document.getElementById("story").value;
            const dataStr = { "cellNumber": cellNumber, "fullName": fullName, "story": story }
            console.log(dataStr)
            let data = new FormData();
            data.append('file', file);
            data.append('customerDto', JSON.stringify(dataStr));
            axiosInstance.post(url, data, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: data => {
                  //Set the progress value to show the progress bar
                  setProgress(Math.round((100 * data.loaded) / data.total))
                },
              }).then((response) => {
               toast.success(response.data.message);
               navigate('/complete',{ replace: true })
                console.log("after")

            }).catch(error =>{
                toast.error(error.message);
            });


        } catch (error) {

            console.log("Error submitting form data:", JSON.stringify(error));
            navigate('/error-page')

        }
    }
    function handleChange(e) {


        setFile(e.target.files[0])

    };

    return (
        <div className="container">
            <div className="col-md-5">
                <div className="form-area">
                    <form onSubmit={handleSubmit}>
                        <br style={{ clear: "both" }} />
                        <div style={{ marginBottom: 25, textAlign: "center" }}>
                            <Header />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id="fullName"
                                name="name"
                                placeholder="Full Name"
                                required=""


                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id="cellNumber"
                                name="cellNumber"
                                placeholder="Mobile Number"
                                required=""

                            />
                        </div>
                        <div className="form-group">
                            <span>
                                Upload Video/Photo
                            </span>
                            <input id="media" type="file" name="media" onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" type="textarea" id="story" placeholder="Story"
                                maxLength={140} rows={7} defaultValue={""} />


                        </div>
                        <button
                            type="button"
                            id="submit"
                            name="submit"
                            className="btn btn-primary center-block" onClick={handleSubmit}  >
                            Submit
                        </button>
                        {progress && <ProgressBar now={progress} label={`${progress}%`} />}

                        <hr style={{ color: 'white' }} />
                        <div align="center" style={{ border: "1px" }}>
                            </div>
                              <hr style={{ color: 'white' }} />
                        <div align="center" style={{ border: "1px" }}>
                            Inspired to change your world</div>
                    </form>
                </div>
            </div>
        </div>
    )
}

