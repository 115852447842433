import React from 'react'
import Form from '../components/Form'; 

export default function Home() {
  
  return (
    <>
    <main>
  <section id="core-concepts">
    <Form/>
   
    </section>
 
    </main>
 
  

</>
  )
}
