import React from 'react'
import Header from '../components/Header';

function Complete() {
  return (
    <div className="container" align="center">
    <div className="col-md-5">
        <div className="form-area">
  
                <br style={{ clear: "both" }} />
                <div style={{ marginBottom: 25, textAlign: "center" }}>
                    <Header />
                </div>
                 <p align="center" style={{color:'white'}}> Thank you for Sharing  your Story with us</p>
         
                <hr align="center" style={{ color: 'white' }} />
                <div align="center" style={{ border: "1px", color:'white'}}>
                    Inspired to change your world</div>
 
        </div>
    </div>
</div>
  )
}

export default Complete