import './App.css';
import Complete from './pages/Complete';
import Home from './pages/Home';
import ErrorPage from './pages/ErrorPage';
import { Route,Routes } from 'react-router-dom';

function App() {
  return (

  
    <Routes>
           <Route path="/error-page" element={<ErrorPage />} />
        <Route path="/complete" element={<Complete />} />
        <Route path="/" element={<Home />}>
    
      </Route>
    </Routes>
 
  );
}

export default App;
